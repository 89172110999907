import { OpportunityConferenceProviders } from '@techspert-io/opportunities';

const signOffFooter = `
<div>[userFirstName] [userLastName] | A world of expertise at your fingertips</div>
<table class="wrapper" width="100%" border="0" cellspacing="0" cellpadding="0"
  style="background-color: #ffffee !important;">
  <tbody>
    <tr bgcolor="#FFFFFF" style="
          background-color: #FFFFFF !important;
        ">
      <td style="
          font-size: 16px;
          line-height: 10px;
          padding: 16px 0;
          background-color: #FFFFFF !important;
        " valign="top" align="left">
        <a href="http://techspert.com/">
          <img class="max-width" border="0" style="
              display: block;
              color: #1e2b4e;
              background-color: #FFFFFF !important;
              text-decoration: none;
              font-family: 'Manrope', Helvetica, sans-serif;
              font-size: 16px;
              text-align: left;
              mso-hide:all
            " width="120" height="75" alt="Techspert - Connections that matter" data-proportionally-constrained="true"
            data-responsive="false"
            src="http://cdn.mcauto-images-production.sendgrid.net/b38c4a0d211c5a44/f62fc024-6260-4315-a6c2-e569ba1bce2b/493x308.png" />
        </a>
      </td>
    </tr>
  </tbody>
</table>
<div style="line-height: 1.5; font-size: 14px;">
<div>Members of the British Healthcare Business Intelligence Association</div>
<div>[office_location]. | Company no: 09874279</div>
<div>Check us out on <a href="https://uk.trustpilot.com/review/techspert.io" target="_blank">Trustpilot</a> and <a href="https://www.linkedin.com/company/techspert-io/" target="_blank">LinkedIn</a></div>
</div> 
`;

export const expertIntroHtml = '<div>Hi [expertFirstName],</div>';
export const clientIntroHtml = '<div>Hi [clientRepFirstName],</div>';
export const replyInstructionsHtml = `<div>Please accept the calendar invitation to confirm this call.</div>`;
export const expertBlindInstructionsText = `If you are joining the call via Zoom link or similar video conferencing software, please do not join with your video on and ensure your display name is anonymized prior to joining the meeting.`;
export const expertBlindInstructionsHtml = `<div style="font-weight: bold; color: red; text-decoration: underline;">${expertBlindInstructionsText}</div>`;
export const clientBlindInstructionsHeaderText = `Research sponsor dial-in instructions:`;
export const clientBlindInstructionsBodyText = `The research sponsor should dial-in by phone, so they cannot view participant names or video. This is to remove the risk of the engagement becoming unblinded. Please only share the phone dial-in numbers, the meeting code and the meeting password with the research sponsor, as listed below.`;
const clientBlindInstructionsHtml = `
  <div style="font-weight: bold;">${clientBlindInstructionsHeaderText}</div>
  <div>${clientBlindInstructionsBodyText}</div>
`;

export const joinWarning =
  '<div>Please do not join more than an hour before the scheduled start time for your conference.</div><br />';

export const paymentTerms =
  '<div>All payments accrued in a calendar month will be paid by the 8th day of the following month.</div>';

export const complianceText = `
<div>
  <div>Please ensure you are familiar with our <a href="https://techspert.com/terms-and-conditions-for-experts/" target="_blank">terms of use and privacy policy</a>. Some key points we wish to highlight:</div>
  <ul>
  <li>You confirm that you will NOT discuss your current employer</li>
  <li>You confirm that you are NOT aware of any present conflicts of interest that would impact your ability to participate in the project</li>
  <li>You confirm that you will NOT disclose any Confidential Information including any that arises from an NDA or any other Contractual Agreement</li>
  </ul>
</div>
`;

const callHostTemplate = `
  <div style="font-weight: bold">Call hosting:</div>
  <div>You will be a host of this conference. As host, you will be able to admit and remove guests from a waiting room, rename attendees, and turn videos on/off.</div>
  <br />
  <div>We recommend keeping the participant list open to monitor the waiting room until all participants have joined.</div>
  <br />
`;

export const templates = {
  new: {
    firstSection: {
      expert: `
        <div>Thank you for agreeing to speak with our client on the topic of [conferenceTopic].</div>
        <div>The selected time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
      `,
      client: `
        <div>Please see below for details regarding your call with your chosen expert ([expertName]) ([geographicTarget], [profileType]), who will be speaking on the topic of [conferenceTopic].</div>
        <div>The selected time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
        ${clientBlindInstructionsHtml}
        <br />
      `,
      expertOpen: `
        <div>Thank you for agreeing to speak on the topic of [conferenceTopic]. I’d like to introduce you to [clientRepFirstName] from [clientName], with whom you will be speaking.</div>
        <div>The selected time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
      `,
      clientOpen: `
        <div>Please see below for details regarding your call with ([expertName]) ([geographicTarget], [profileType]), who will be speaking on the topic of [conferenceTopic].</div>
        <div>The selected time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
      `,
    },
  },
  update: {
    firstSection: {
      expert: `
        <div>Thank you for agreeing to speak with our client on the topic of [conferenceTopic].</div>
        <div>The updated time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
      `,
      client: `
        <div>Please see below for details regarding your call with your chosen expert ([expertName]) ([geographicTarget], [profileType]), who will be speaking on the topic of [conferenceTopic].</div>
        <div>The updated time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
        ${clientBlindInstructionsHtml}
        <br />
      `,
      expertOpen: `
        <div>Please see below the updated details regarding your call with [clientRepFirstName] from [clientName] on the topic of [conferenceTopic].</div>
        <div>The updated time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
      `,
      clientOpen: `
        <div>Please see below the updated details regarding your call with ([expertName]) ([geographicTarget], [profileType]), who will be speaking on the topic of [conferenceTopic].</div>
        <div>The updated time to call is <b>[callTime]</b>.</div>
        <br />
        ${replyInstructionsHtml}
        <br />
      `,
    },
  },
  cancel: {
    firstSection: {
      expert: `
        <div>Your upcoming call at <b>[callTime]</b> on the topic of [conferenceTopic] has been cancelled.</div>
        <br />
      `,
      client: `
        <div>Your upcoming call at <b>[callTime]</b> on the topic of [conferenceTopic] has been cancelled.</div>
        <br />
      `,
      expertOpen: `
      <div>Your upcoming call at <b>[callTime]</b> on the topic of [conferenceTopic] has been cancelled.</div>
      <br />
      `,
      clientOpen: `
      <div>Your upcoming call at <b>[callTime]</b> on the topic of [conferenceTopic] has been cancelled.</div>
      <br />
      `,
    },
  },
  callHostDetails: {
    [OpportunityConferenceProviders.ZoomTechspert]: callHostTemplate,
    [OpportunityConferenceProviders.MeetTechspert]: callHostTemplate,
    [OpportunityConferenceProviders.ClientHosted]: callHostTemplate,
    [OpportunityConferenceProviders.Other]: '',
  },
  meetingDetails: {
    [OpportunityConferenceProviders.ZoomTechspert]: {
      ['guest-link']: `
      <div style="font-weight: bold">Connecting via internet:</div>
      <div>Host link: <a href="[webLink]">[webLink]</a></div>
      <div>Meeting Id: [zoomId]</div>
      <div>Meeting password: [zoomPassword]</div>
      <br />
      <div>Guest link: <a href="[guestLink]">[guestLink]</a></div>
      <br />
      [dialIns]
    `,
      standard: `
      <div style="font-weight: bold">Connecting via internet:</div>
      <div>Weblink: <a href="[webLink]">[webLink]</a></div>
      <div>Meeting Id: [zoomId]</div>
      <div>Meeting password: [zoomPassword]</div>
      <br />
      [dialIns]
    `,
    },
    [OpportunityConferenceProviders.MeetTechspert]: {
      ['guest-link']: `
      <div style="font-weight: bold">Connecting via Techspert Meet:</div>
      <div>Host link: <a href="[conference_meeting_link]">[conference_meeting_link]</a></div>
      <div>Guest link: <a href="[conference_meeting_guest_link]">[conference_meeting_guest_link]</a></div>
      <br />
    `,
      standard: `
      <div style="font-weight: bold">Connecting via Techspert Meet:</div>
      <div>Weblink: <a href="[conference_meeting_link]">[conference_meeting_link]</a></div>
      <br />
  `,
    },
    [OpportunityConferenceProviders.ClientHosted]: {
      ['guest-link']: `
      <div style="font-weight: bold">Connecting via Client-hosted Meet:</div>
      <div>Host link: <a href="[webLink]">[webLink]</a></div>
      <br />
      <div>Guest link: <a href="[guestLink]">[guestLink]</a></div>
      <br />
      [dialIns]
    `,
      standard: `
      <div style="font-weight: bold">Connecting via Client-hosted Meet:</div>
      <div>Weblink: <a href="[webLink]">[webLink]</a></div>
      <br />
      [dialIns]
    `,
    },
    [OpportunityConferenceProviders.Other]: {},
  },
  intro: {
    expert: `${expertIntroHtml}<br />`,
    client: `${clientIntroHtml}<br />`,
  },
  footer: {
    expert: `
        <br />
        ${signOffFooter}  
      `,
    client: `
        <div>
        <a href="https://techspert.com/terms-and-conditions-for-customers/" target="_blank">Terms & Conditions for clients</a>
        </div>
        <br />
        ${signOffFooter}
      `,
    open: `
        <div>If you have any questions or concerns please get in touch at <a target="_blank" href="mailto:[userEmail]">[userEmail]</a>.</div>
        <div>
        <a href="https://techspert.com/terms-and-conditions-for-experts/" target="_blank">Terms & Conditions for experts</a>
        </div>
        <div>
        <a href="https://techspert.com/terms-and-conditions-for-customers/" target="_blank">Terms & Conditions for clients</a>
        </div>
        <br />
        ${signOffFooter}
      `,
  },
};
