import { createReducer, on } from '@ngrx/store';
import {
  ExpertProfileActions,
  ExpertProfileAPIActions,
} from './expert-profiles.actions';
import {
  expertProfileAdapter,
  IExpertProfileState,
} from './expert-profiles.state';

export const initialState: IExpertProfileState =
  expertProfileAdapter.getInitialState({
    isLoading: false,
    isLoaded: false,
    selectedExpertProfileId: null,
    updateErrors: null,
    loadError: null,
  });

export const expertProfilesReducer = createReducer(
  initialState,
  on(
    ExpertProfileActions.fetchExpertProfile,
    (state, { expertProfileId }): IExpertProfileState => ({
      ...state,
      selectedExpertProfileId: expertProfileId,
      isLoading: true,
      isLoaded: false,
      loadError: null,
      updateErrors: null,
    })
  ),
  on(
    ExpertProfileAPIActions.fetchExpertProfileSuccess,
    (state, { expertProfile }) =>
      expertProfileAdapter.addOne(expertProfile, {
        ...state,
        isLoading: false,
        isLoaded: true,
      })
  ),
  on(ExpertProfileAPIActions.fetchExpertProfileFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    isLoaded: false,
    loadError: error.join(', '),
  })),
  on(
    ExpertProfileActions.updateExpertProfile,
    (state): IExpertProfileState => ({
      ...state,
      isLoading: true,
      updateErrors: null,
      loadError: null,
    })
  ),
  on(
    ExpertProfileAPIActions.updateExpertProfileSuccess,
    (state, { expertProfile }) =>
      expertProfileAdapter.updateOne(
        { id: expertProfile.expertProfileId, changes: expertProfile },
        { ...state, isLoading: false }
      )
  ),
  on(
    ExpertProfileAPIActions.updateExpertProfileFailure,
    (state, { error }): IExpertProfileState => ({
      ...state,
      isLoading: false,
      updateErrors: error,
    })
  )
);

export const getSelectedExpertProfileId = (
  state: IExpertProfileState
): string => state.selectedExpertProfileId;

const { selectIds, selectEntities, selectAll, selectTotal } =
  expertProfileAdapter.getSelectors();

export const selectExpertProfileIds = selectIds;
export const selectExpertProfileEntities = selectEntities;
export const selectAllExpertProfiles = selectAll;
export const selectExpertProfileTotal = selectTotal;
