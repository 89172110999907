<form [formGroup]="settingsForm" class="flex flex-col gap-6">

  <div class="flex flex-col gap-4">
    <div class="flex gap-4 flex-1">
      <mat-form-field class="basis-1/4">
        <mat-label data-test-id="opportunity-details-settings-call-duration-label">
          Anticipated call time (mins)
        </mat-label>
        <input matInput type="number" formControlName="anticipatedCallTime" placeholder="Call time"
          data-test-id="opportunity-details-settings-call-duration">
      </mat-form-field>
      <ng-container *ngIf="settingsForm.value.projectType === 'Qual'">
        <mat-form-field class="basis-1/4">
          <mat-label>Default conference provider</mat-label>
          <mat-select formControlName="defaultConferenceProvider">
            <mat-option *ngFor="let option of conferenceProviderOptions" [value]="option">
              {{ OpportunityConferenceProviderOptionLabels[option] }}
            </mat-option>
          </mat-select>
          <mat-error> Required </mat-error>
        </mat-form-field>
      </ng-container>
      <mat-form-field class="flex-1">
        <mat-label data-test-id="opportunity-details-settings-conference-topic-label">
          Default conference topic
        </mat-label>
        <input matInput formControlName="defaultConferenceTopic" placeholder="Conference topic"
          data-test-id="opportunity-details-settings-conference-topic">
        <mat-error data-test-id="opportunity-details-settings-conference-topic-error">
          Conference topic is required
        </mat-error>
      </mat-form-field>
    </div>

    <div class="flex flex-row gap-4">
      <mat-form-field class="flex-1">
        <mat-label data-test-id="opportunity-details-settings-description-label">Description - internal</mat-label>
        <textarea matInput formControlName="notes" placeholder="Notes"
          data-test-id="opportunity-details-settings-description"></textarea>
        <mat-error data-test-id="opportunity-details-description-validation-error"> Description is required </mat-error>
        <mat-hint data-test-id="opportunity-details-settings-description-help-text">
          Please document the client's requirements as accurately as possible to help streamline communication
          within the Techspert team
        </mat-hint>
      </mat-form-field>

      <mat-form-field class="flex-1">
        <mat-label data-test-id="opportunity-details-settings-project-scope-label">Project scope - internal</mat-label>
        <textarea matInput formControlName="projectScope" placeholder="Project scope"
          data-test-id="opportunity-details-settings-project-scope"></textarea>
        <mat-hint data-test-id="opportunity-details-settings-project-scope-help-text">
          Information on the client's original request
        </mat-hint>
      </mat-form-field>
    </div>

    <div class="flex flex-row gap-4">
      <mat-form-field class="flex-1">
        <mat-label data-test-id="opportunity-details-settings-project-pitch-label">Project pitch - external</mat-label>
        <textarea matInput formControlName="defaultProjectPitch" placeholder="Project pitch"
          data-test-id="opportunity-details-settings-project-pitch"></textarea>
        <mat-hint data-test-id="opportunity-details-settings-project-pitch-help-text">
          Will be shown to the expert on the expert portal landing page
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
</form>