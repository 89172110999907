<form [formGroup]="parentForm">
  <div [formArrayName]="specialismName" class="flex flex-col">
    <h2 class="flex flex-row">
      {{specialismName | titlecase}}
    </h2>
    <div class="flex flex-row gap-4">
      <div class="flex flex-row gap-4 basis-[300px]">
        <mat-form-field class="flex-1">
          <mat-label>Add {{specialismName}}</mat-label>
          <input matInput [formControl]="specialsimInput" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of autoCompleteOptions$ | async" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>Required</mat-error>
        </mat-form-field>
        <button mat-mini-fab color="primary" (click)="add()" matTooltip="Add specialism">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div class="flex flex-row justify-end content-stretch items-stretch h-full flex-1">
        <button mat-mini-fab color="warn" (click)="removeOriginals()" matTooltip="Delete all original">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <ng-container *ngFor="let ctrl of specialismControls; index as i">
      <ng-container [formGroupName]="i" *ngIf="specialismControls[i].value.status !== 'removed'">
        <div class="specialism-row flex justify-start content-center items-center flex-row gap-2">
          <div class="specialsim-name flex-1">{{specialismControls[i].value.name}}</div>
          <div>
            <button mat-icon-button (click)="update(i, 'confirmed')" matTooltip="Confirmed">
              <mat-icon [color]="specialismControls[i].value.status === 'confirmed' && 'accent'">check</mat-icon>
            </button>
            <button mat-icon-button (click)="update(i, 'mismatch')" matTooltip="Mismatch">
              <mat-icon [color]="specialismControls[i].value.status === 'mismatch' && 'primary'">block</mat-icon>
            </button>
            <button mat-icon-button (click)="update(i, 'removed')" matTooltip="Removed">
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </ng-container>

  </div>
</form>