import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ClientActions,
  IClient,
  IClientDefaultTemplate,
  IClientSettings,
  selectCurrentClient,
} from '@techspert-io/clients';
import {
  OpportunityConferenceProviderOptionLabels,
  OpportunityConferenceProviders,
} from '@techspert-io/opportunities';
import { Observable, Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-opportunity-kick-off-template',
  templateUrl: './opportunity-kick-off-template.component.html',
  styleUrl: './opportunity-kick-off-template.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OpportunityKickOffTemplateComponent implements OnInit, OnDestroy {
  @Input() client: IClient;
  @Output() clientTemplateUpdate = new EventEmitter<
    Partial<IClientDefaultTemplate>
  >();

  OpportunityConferenceProviders = OpportunityConferenceProviders;
  OpportunityConferenceProviderOptionLabels =
    OpportunityConferenceProviderOptionLabels;
  conferenceProviderOptions = Object.values(OpportunityConferenceProviders);
  isSubmitted = false;

  client$: Observable<IClient>;

  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private store: Store) {}

  kickoffTemplateForm = new FormGroup({
    anticipatedCallTime: new FormControl<number | null>(null),
    defaultConferenceProvider: new FormControl<string | null>(null),
    settings: new FormControl<Partial<IClientSettings>>({}),
    createSlackChannel: new FormControl<boolean | null>(null),
  });

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        tap((params) => {
          this.store.dispatch(
            ClientActions.fetchClient({
              clientId: params.clientId,
            })
          );
        })
      )
      .subscribe();

    this.client$ = this.store.select(selectCurrentClient).pipe(
      tap((client) => {
        if (client) {
          this.setupFormDefaults(client);
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  updateOpportunityDefaultTemplate(
    clientId: string,
    opportunityDefaultTemplate: Partial<IClientDefaultTemplate>
  ): void {
    this.store.dispatch(
      ClientActions.updateClient({
        client: {
          clientId,
          opportunitiesTemplate: opportunityDefaultTemplate,
        },
      })
    );
  }

  onSubmit(clientId: string): void {
    this.kickoffTemplateForm.markAllAsTouched();
    this.isSubmitted = true;

    if (this.kickoffTemplateForm.valid) {
      this.updateOpportunityDefaultTemplate(clientId, {
        ...this.kickoffTemplateForm.value,
      } as Partial<IClientDefaultTemplate>);
    }
  }

  private setupFormDefaults(client: Partial<IClient>): void {
    this.kickoffTemplateForm.setValue({
      anticipatedCallTime:
        client.opportunitiesTemplate?.anticipatedCallTime || 60,
      defaultConferenceProvider:
        client.opportunitiesTemplate?.defaultConferenceProvider || null,
      settings: {
        callRecording:
          client.opportunitiesTemplate?.settings?.callRecording || null,
        inProjectReferralEnabled:
          client.opportunitiesTemplate?.settings?.inProjectReferralEnabled ||
          null,
        excludeExpertPIIForClientEmails:
          client.opportunitiesTemplate?.settings
            ?.excludeExpertPIIForClientEmails || null,
        screenerComparisonEnabled:
          client.opportunitiesTemplate?.settings?.screenerComparisonEnabled ||
          null,
        enrichedSchedulerInvite:
          client.opportunitiesTemplate?.settings?.enrichedSchedulerInvite ||
          null,
        automatedScheduling:
          client.opportunitiesTemplate?.settings?.automatedScheduling || null,
        blind: client.opportunitiesTemplate?.settings?.blind || null,
        isClientHostEnabled:
          client.opportunitiesTemplate?.settings?.isClientHostEnabled || null,
        expertBlockProfileOutreachEnabled:
          client.opportunitiesTemplate?.settings
            ?.expertBlockProfileOutreachEnabled || null,
        sendExpertPaymentEmail:
          client.opportunitiesTemplate?.settings?.sendExpertPaymentEmail ||
          null,
        profileEditEnabled:
          client.opportunitiesTemplate?.settings?.profileEditEnabled || null,
      },
      createSlackChannel:
        client.opportunitiesTemplate?.createSlackChannel || null,
    });
  }
}
